import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImageHelper'; // Import the fixed function
import { resizeImageTo500KB } from './imageUtils';
import { readFileAsDataURL } from './readFileAsDataURL';

const ImageCropComponent = ({ handleCroppedImageChange }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(() => {
    // Load the cropped image from localStorage on component mount
    const savedImage = localStorage.getItem('croppedImage');
    return savedImage || null;
  });
  const [isCropped, setIsCropped] = useState(!!croppedImage); // Track if the image is cropped

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = await readFileAsDataURL(file);
      setImageSrc(imageDataUrl);
      setIsCropped(false); // Reset the cropped state when a new image is selected
    }
  };

  const handleCropAndShowImage = async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);

      if (!(croppedBlob instanceof Blob)) {
        console.error('Cropped image is not a Blob');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(croppedBlob);
      reader.onloadend = async () => {
        const base64Image = reader.result;
        const resizedImage = await resizeImageTo500KB(base64Image);
        setCroppedImage(resizedImage);
        setIsCropped(true);
        handleCroppedImageChange(resizedImage);

        localStorage.setItem('croppedImage', resizedImage);
        console.log('Cropped image saved to localStorage');
      };
    } catch (error) {
      console.error('Error cropping/resizing the image:', error);
    }
  };

  const removeCroppedImage = () => {
    localStorage.removeItem('croppedImage');
    setCroppedImage(null);
    setIsCropped(false);
    setImageSrc(null);
    handleCroppedImageChange(null); // Notify parent component about removal
  };

  const styles = {
    container: { textAlign: 'center', marginTop: '20px' },
    cropContainer: { position: 'relative', width: '100%', height: 400, display: isCropped ? 'none' : 'block' },
    croppedImage: { maxWidth: '100%', height: 'auto', marginTop: '20px' },
    fileInput: { display: 'none' },
    fileInputLabel: { padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', borderRadius: '4px', cursor: 'pointer', fontWeight: 'bold' },
    cropButton: { padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' },
    removeButton: { padding: '10px 20px', backgroundColor: '#dc3545', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' },
  };

  return (
    <div style={styles.container}>
      <label htmlFor="fileInput" style={styles.fileInputLabel}>
        Choose Image
      </label>
      <input id="fileInput" type="file" onChange={handleImageChange} style={styles.fileInput} />
      {imageSrc && (
        <>
          <div style={styles.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={4 / 4}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          {!isCropped && (
            <button type="button" onClick={handleCropAndShowImage} style={styles.cropButton}>
              Crop and Show Image
            </button>
          )}
        </>
      )}
      {croppedImage && (
        <div>
          <h3>Cropped Image:</h3>
          <img src={croppedImage} alt="Cropped Preview" style={styles.croppedImage} />
          <button type="button" onClick={removeCroppedImage} style={styles.removeButton}>
            Remove Image
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageCropComponent;
