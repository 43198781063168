import React from 'react';
import './FeedbackSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import flowerIcon from '../assets/Shape 44 1.svg';

const feedbacks = [
  {
    text: 'माझा बायोडाटा माध्यमामुळे माझा खूप वेळ वाचला. कमी किमतीमध्ये मला बायोडाटा बनवता आला. माझा बायोडाटा वरती अतिशय आकर्षक टेम्प्लेट उपलब्ध आहेत.',
    name: 'श्रीनिवास कदम',
  },
  {
    text: 'मला हवा तसा बायोडाटा खूप कमी वेळात बनवता आला. मला माझ्या आवडीचा टेम्प्लेट निवडता आला आणि वेळेची बचत झाली. मला हवा तसा जोडीदारही मिळाला- ',
    name: 'विशाखा पाटील',
  },
  {
    text: 'अतिशय जलद, सोपी, आणि किफायतशीर सेवा! या माध्यमामुळे मला माझा बायोडाटा प्रभावीपणे सादर करता आला. टेम्प्लेट्स खूप चांगल्या दर्जाच्या आहेत.',
    name: 'रोहित शिंदे',
  }
];

const FeedbackSlider = () => {
  return (
    <div className="feedback-slider">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        speed={1000}
        modules={[Autoplay, Pagination]}
        pagination={{ clickable: true }}
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            },
            speed: 4000
          }
        }}
      >
        {feedbacks.map((feedback, index) => (
          <SwiperSlide key={index}>
            <div className="feedback-card">
            <div className='angry'>

              <div className="avatar"></div>
              <h3>{feedback.name}</h3>
              </div>

              <p>{feedback.text}</p>
              <div className="decoration">
                <img src={flowerIcon} alt="flower icon" />
                <img src={flowerIcon} alt="flower icon" />
                <img src={flowerIcon} alt="flower icon" />
                <img src={flowerIcon} alt="flower icon" />
                <img src={flowerIcon} alt="flower icon" />

               
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FeedbackSlider;
