import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument"; // Adjust the path as needed
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
import "./PreviewPage.css"
import jsPDF from "jspdf";
import axios from 'axios';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PreviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formData, templateId: initialTemplateId, imagePreview, centerText } = location.state;
  const [additionalImage] = useState(() => {
    const storedImage = localStorage.getItem("croppedImage");
    return storedImage || null; // Use the stored image or null if not present
  });
  
  

  const [templateId, setTemplateId] = useState(initialTemplateId);
  const [loading, setLoading] = useState(false);
  const [pdfImage, setPdfImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const templates = Array.from({ length: 12 }, (_, i) => i + 1);

  useEffect(() => {
    const generatePdfPreview = async () => {
      setLoading(true);
      try {
        // Generate the PDF blob
        const pdfBlob = await pdf(
          <PDFDocument
            formData={formData}
            templateId={templateId}
            {...(additionalImage && { additionalImage })}
            imagePreview={imagePreview}
            centerText={centerText}
          />
        ).toBlob();
  
        // Convert the PDF to an image for preview
        const pdfArrayBuffer = await pdfBlob.arrayBuffer();
        const pdfjsLib = await import("pdfjs-dist/legacy/build/pdf"); // Dynamically import pdfjs-dist
        const pdfDocument = await pdfjsLib.getDocument({ data: pdfArrayBuffer }).promise;
  
        const page = await pdfDocument.getPage(1);
        const viewport = page.getViewport({ scale: 2.0 }); // Adjust scale for better resolution
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;
  
        // Render the PDF page onto the canvas
        await page.render({ canvasContext: context, viewport }).promise;
  
        // Add a watermark to the canvas
        context.font = "bold 100px Arial";
        context.fillStyle = "rgba(82, 82, 82, 1.5)"; // Semi-transparent red
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate((-45 * Math.PI) / 180); // Rotate watermark text
        context.fillText("mazabiodata.com", 0, 0);
  
        // Convert the canvas to a data URL for display
        const imageUrl = canvas.toDataURL("image/png");
        setPdfImage(imageUrl);
      } catch (error) {
        console.error("Error generating PDF image:", error);
      } finally {
        setLoading(false);
      }
    };
  
    generatePdfPreview();
  }, [formData, templateId, additionalImage, imagePreview, centerText]);
  

  const downloadPDF = async () => {
    setShowLoader(true); // Show loader
    try {
      const pdfBlob = await pdf(
        <PDFDocument
          formData={formData}
          templateId={templateId}
          additionalImage={additionalImage}
          imagePreview={imagePreview}
          centerText={centerText}
        />
      ).toBlob();
  
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      const userName = formData["नाव"]?.value || "download";
      link.href = url;
      link.download = `${userName}_बायोडाटा.pdf`;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setShowLoader(false); // Hide loader
    }
  };
  

  const downloadPDFWithWatermark = async () => {
    if (!pdfImage) {
      alert("Preview image not available.");
      return;
    }
  
    try {
      // Create a new jsPDF instance
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [600, 800], // Adjust as needed based on your image dimensions
      });
  
      // Load the preview image into the PDF
      doc.addImage(pdfImage, "PNG", 0, 0, 600, 800);
  
      // Add the watermark text
   
  
      // Initiate download
      const userName = formData["नाव"]?.value || "download";
      doc.save(`${userName}_बायोडाटा_with_watermark.pdf`);
    } catch (error) {
      console.error("Error generating PDF with watermark:", error);
    }
  };

  const handlePaymentAndDownload = async () => {
    if (!window.Razorpay) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
  
    setShowLoader(true); // Show loader
    const options = {
      key: "rzp_live_MNTS6cIICe8F3n",
      amount: 2900,
      currency: "INR",
      name: "Your Company",
      description: "Download PDF without watermark",
      handler: async function (response) {
        try {
          const res = await axios.post('https://pratikmal01.pythonanywhere.com/capture-payment', {
            payment_id: response.razorpay_payment_id,
            amount: 2900,
          });
  
          if (res.data.success) {
            alert("Payment captured successfully!");
            await downloadPDF();
          } else {
            alert("Payment capture failed. Please try again.");
          }
        } catch (error) {
          console.error("Error capturing payment:", error);
          alert("Something went wrong. Please try again.");
        } finally {
          setShowLoader(false); // Hide loader
        }
      },
      prefill: {
        name: "Mazabiodata",
        email: "mazabiodata@gmail.com",
        contact: "917776914543",
      },
      theme: {
        color: "#3399cc",
      },
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  
  

  const handleEditClick = () => {
    navigate("/input-form/1", {
      state: {
        initialFormData: formData,
        imagePreview,
        
        centerText,
        additionalImage,
        templateId,
      },
    });
  };

  return (
    <div className="preview-container">
      <h1>Preview for Template {templateId}</h1>
      {loading ? (
        <div className="loading-overlay">Loading...</div>
      ) : pdfImage ? (
        <div className="pdf-image-preview">
          <img src={pdfImage} alt="PDF Preview" className="pdf-image" />
        </div>
      ) : (
        <p>No preview available.</p>
      )}
      <div className="buttons-container">
      <button onClick={downloadPDFWithWatermark}>वॉटरमार्कसह डाउनलोड</button>

        <button onClick={handlePaymentAndDownload}>पेमेंट आणि डाउनलोड</button>
        <button onClick={handleEditClick}>माहिती दुरुस्त करा</button>
      </div>
      <div className="template-selection">
        {templates.map((id) => (
          <img
            key={id}
            src={`/Marriage Biodata Templatep-${id < 10 ? `0${id}` : id}.png`}
            alt={`Template ${id}`}
            className={`template-thumbnail ${templateId === `${id}` ? "selected" : ""}`}
            onClick={() => setTemplateId(`${id}`)}
          />
        ))}
        {showLoader && <div className="full-page-loader">Loading, please wait...</div>}

      </div>
    </div>

    
  );
};

export default PreviewPage;
