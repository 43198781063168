// Import the Firebase modules
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// Your Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDC37Ze0VqeG03EoRJbaAqo7eyifioHnN0",
  authDomain: "cvmachine-74913.firebaseapp.com",
  databaseURL: "https://cvmachine-74913-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cvmachine-74913",
  storageBucket: "cvmachine-74913.firebasestorage.app",
  messagingSenderId: "463176878357",
  appId: "1:463176878357:web:8702353216c4f1f52dc11c"
};

// Initialize Firebase App
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Export Firestore
export const db = getFirestore(app);