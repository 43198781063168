import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BusinessComponent.css';

import TemplateSlider from './TemplateSlider';

const ImageTemplatesComponent = () => {


  const navigate = useNavigate();
  const [selectedCategory] = useState('All');
  const [previewTemplate, setPreviewTemplate] = useState(null);

  const templates = [
    { id: 1, category: 'Hindu', imageUrl: './Marriage Biodata Template-01.png', bestSeller: true },
    // { id: 23, category: 'Muslim', imageUrl: './Marriage Biodata Template-23.png', bestSeller: false },
    // { id: 24, category: 'Muslim', imageUrl: './Marriage Biodata Template-24.png', bestSeller: false },
    // { id: 25, category: 'Muslim', imageUrl: './Marriage Biodata Template-25.png', bestSeller: false },
    // { id: 26, category: 'Muslim', imageUrl: './Marriage Biodata Template-26.png', bestSeller: false },
    // { id: 27, category: 'Muslim', imageUrl: './Marriage Biodata Template-27.png', bestSeller: false },
    { id: 2, category: 'Muslim', imageUrl: './Marriage Biodata Template-02.png', bestSeller: false },
    { id: 3, category: 'Hindu', imageUrl: './Marriage Biodata Template-03.png', bestSeller: false },
    { id: 4, category: 'Hindu', imageUrl: './Marriage Biodata Template-04.png', bestSeller: false },
    { id: 5, category: 'Hindu', imageUrl: './Marriage Biodata Template-05.png', bestSeller: false },
    { id: 6, category: 'Hindu', imageUrl: './Marriage Biodata Template-06.png', bestSeller: false },



    { id: 7, category: 'Muslim', imageUrl: './Marriage Biodata Template-07.png', bestSeller: false },
    { id: 8, category: 'Jain', imageUrl: './Marriage Biodata Template-08.png', bestSeller: true },
    { id: 9, category: 'Hindu', imageUrl: './Marriage Biodata Template-09.png', bestSeller: false },
    { id: 10, category: 'Baudhha', imageUrl: './Marriage Biodata Template-10.png', bestSeller: false },
    { id: 11, category: 'Jain', imageUrl: './Marriage Biodata Template-11.png', bestSeller: false },
    { id: 12, category: 'Jain', imageUrl: './Marriage Biodata Template-12.png', bestSeller: false },
  
   

  ];

  // const handleCategoryChange = (category) => {
  //   setSelectedCategory(category);
  // };

  const handleCustomizeClick = (template) => {
    navigate(`/input-form/${template.id}`);

    

  };

  const handlePreviewClick = (template) => {
    setPreviewTemplate(template);
  };

  const handleClosePreview = () => {
    setPreviewTemplate(null);
  };



  const filteredTemplates = selectedCategory === 'All'
    ? templates
    : templates.filter((template) => template.category === selectedCategory);

  return (
    <div className="business-container" style={{ marginTop: '20px' }}>
   
    

    

      <section   
 className="templates-section" id="templates-section">
        <h2>आमचे Templates</h2>
        <TemplateSlider
          templates={filteredTemplates}
          onCustomizeClick={handleCustomizeClick}
          onPreviewClick={handlePreviewClick}
        />
      </section>


      {previewTemplate && (
        <div className="preview-modal">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>✖</button>
            <img src={previewTemplate.imageUrl} alt={`Template ${previewTemplate.id}`} />
            <button className="template-button" onClick={() => handleCustomizeClick(previewTemplate)}>Customize</button>
          </div>
        </div>
      )}
      
    
    </div>
  );
};

export default ImageTemplatesComponent;
